import { Button, Card, Form, Input, message, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../components/PageWrapper';
import { login } from '../utils/redux/slices/auth';
import { useMutation } from 'react-query';
import { saveToken } from '../utils/manage-token';

const SurveyorLoginPage = () => {
	const navigate = useNavigate();

	const { mutate: appLogin, isLoading } = useMutation(login, {
		onSuccess: (data) => {
			saveToken(data.data?.token);
			message.success('Login successful');
			navigate('/dashboard');
		},
		onError: (error: any) => {
			notification['error']({
				message: 'Action Failed',
				description: error?.message,
			});
		},
	});

	const handleSubmit = (value: any) => {
		appLogin(value);
	};

	return (
		<PageWrapper title="Surveyor Login">
			<Card>
				<h1>Sign in to your account</h1>
				<br />
				<Form onFinish={handleSubmit}>
					<Form.Item name="email" label="Email">
						<Input size="large" required autoComplete="off" />
					</Form.Item>
					<Form.Item name="password" label="Password">
						<Input.Password size="large" required />
					</Form.Item>
					<Form.Item>
						<Button
							disabled={isLoading}
							loading={isLoading}
							size="large"
							type="primary"
							htmlType="submit"
							block
						>
							Sign in
						</Button>
					</Form.Item>
					<p>
						Don&apos;t have account?{' '}
						<Link to="/create-demo-account">Create Account</Link>
					</p>
				</Form>
			</Card>
		</PageWrapper>
	);
};

export default SurveyorLoginPage;
