import { RouteObject } from 'react-router-dom';
import LandingPage from '../pages/Landing';
import CreateAccountPage from '../pages/CreateAccount';
import SurveyorLoginPage from '../pages/Login';

const unauthenticated: RouteObject[] = [
	{
		path: '',
		element: <LandingPage />,
	},
	{
		path: 'create-demo-account',
		element: <CreateAccountPage />,
	},
	{
		path: 'auth',
		element: <SurveyorLoginPage />,
	},
];

export default unauthenticated;
