import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../utils/manage-token';

const UnAuthenticatedRoutes = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (isAuthenticated()) {
			navigate('/dashboard');
		}
	}, [navigate]);

	if (!isAuthenticated()) {
		return (
			<div>
				<Outlet />
			</div>
		);
	}

	return null;
};

export default UnAuthenticatedRoutes;
