import { Divider } from 'antd';
import PageWrapper from '../../components/PageWrapper';
import AdminList from './AdminList';
import SurveyorsList from './SurveyorsList';

const ApplicationUsers = () => {
	return (
		<PageWrapper title="Application Users">
			<SurveyorsList />
			<Divider />
			<AdminList />
		</PageWrapper>
	);
};

export default ApplicationUsers;
