import { Button, Card, Divider, Form, Input, Select, message } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PageWrapper from '../components/PageWrapper';
import {
	createAdminDemoAccount,
	createSurveyorDemoAccount,
} from '../utils/redux/slices/auth';
import { useMutation } from 'react-query';

const CreateAccountPage = () => {
	const navigate = useNavigate();

	const [userType, setUserType] = useState('');

	const { mutate: adminAccount, isLoading: adminLoading } = useMutation(
		createAdminDemoAccount,
		{
			onSuccess: () => {
				message.success('Account created successfully!');
				navigate('/auth');
			},
			retry: 0,
		}
	);
	const { mutate: surveyorAccount, isLoading } = useMutation(
		createSurveyorDemoAccount,
		{
			onSuccess: () => {
				message.success('Account created successfully!');
				navigate('/auth');
			},
			retry: 0,
		}
	);

	const handleSubmit = (value: any) => {
		if (userType === 'admin') {
			delete value.suffix;
			adminAccount(value);
		} else {
			surveyorAccount(value);
		}
	};

	return (
		<PageWrapper title="Create Demo Account">
			<Card>
				<h1>Create an account</h1>
				<p>
					Create your account and manage your beacon number requests. This is
					for surveyors and administrators
				</p>
				<p style={{ color: 'red' }}>
					<strong>NB: This feature is only for demo purposes</strong>
				</p>

				<Divider />

				<Form name="basic" onFinish={handleSubmit}>
					<Form.Item name="firstName" label="First Name" required>
						<Input size="large" required />
					</Form.Item>
					<Form.Item name="lastName" label="Last Name">
						<Input size="large" />
					</Form.Item>
					<Form.Item name="email" label="Email" required>
						<Input size="large" required />
					</Form.Item>
					<Form.Item name="password" label="Password" required>
						<Input.Password size="large" required />
					</Form.Item>
					<Form.Item label="Account Type" required>
						<Select
							placeholder="Are you a surveyor or admin?"
							onChange={(val) => setUserType(val)}
							allowClear
							size="large"
						>
							<Select.Option value="surveyor">Surveyor</Select.Option>
							<Select.Option value="admin">Admin</Select.Option>
						</Select>
					</Form.Item>
					{userType === 'surveyor' && (
						<Form.Item name="suffix" label="Suffix">
							<Input size="large" />
						</Form.Item>
					)}
					<Form.Item>
						<Button
							disabled={isLoading || adminLoading}
							size="large"
							block
							type="primary"
							htmlType="submit"
						>
							Submit
						</Button>
					</Form.Item>
					<Form.Item label="">
						<p>
							Already have account? <Link to="/auth">Login</Link>
						</p>
					</Form.Item>
				</Form>
			</Card>
		</PageWrapper>
	);
};

export default CreateAccountPage;
