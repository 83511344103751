import {
	Button,
	Card,
	Divider,
	Form,
	Input,
	Select,
	Upload,
	message,
	notification,
} from 'antd';
import PageWrapper from '../components/PageWrapper';
import { createRequest } from '../utils/redux/slices/pillar-num-requests';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getProfile } from '../utils/endpoints/profile';
import { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';

const CreatePillarNumberRequest = () => {
	const navigate = useNavigate();

	const { data: profile } = useQuery('profile', getProfile, { retry: 0 });

	const [fileList, setFileList] = useState<Array<any>>([]);
	const [authorityLetter, setAuthorityLetter] = useState<Array<any>>([]);

	const { mutate: createNewRequest, isLoading } = useMutation(createRequest, {
		onError: (error: any) => {
			notification['error']({
				message: 'Action Failed',
				description: error.message,
			});
		},
		onSuccess: () => {
			message.success('Request created successfully!');
			navigate('/dashboard');
		},
	});

	const convertFileToBase64String = async (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	};

	const handleSubmit = async (values: any) => {
		const documents = await Promise.all(
			fileList.concat(authorityLetter).map(async (x: any) => {
				return await convertFileToBase64String(x);
			})
		);

		values = { ...values, documents };

		createNewRequest({
			id: profile?.profile?.id,
			body: values,
		});
	};

	return (
		<PageWrapper title="Create Request">
			<Card style={{ paddingBottom: 50 }}>
				<h1>Pillar Request Form</h1>
				<p>Complete the form below to create a pillar number request</p>

				<Divider />

				<Form onFinish={handleSubmit}>
					<Form.Item name="surveyLocation" label="Location of Survey" required>
						<Input size="large" required />
					</Form.Item>
					<Form.Item name="area" label="Area of Plot">
						<Input
							size="large"
							type="number"
							required
							addonAfter={
								<Select defaultValue="meters">
									<Select.Option value="meters">Sq.Meters</Select.Option>
									<Select.Option value="hectares">Hectares</Select.Option>
								</Select>
							}
						/>
					</Form.Item>
					<Form.Item name="pillarCount" required label="No. of Pillar Numbers">
						<Input size="large" required type="number" addonAfter="Pillars" />
					</Form.Item>
					<Form.Item name="planNumber" label="Plan Number">
						<Input size="large" />
					</Form.Item>
					<Form.Item label="Letter of Authority">
						<Upload
							accept="image/png,image/jpeg,image/jpg"
							onRemove={(file) => {
								const index = authorityLetter.indexOf(file);
								const newFileList = authorityLetter.slice();
								newFileList.splice(index, 1);
								setAuthorityLetter(newFileList);
							}}
							fileList={authorityLetter}
							beforeUpload={(file: any) => {
								setAuthorityLetter([...authorityLetter, file]);
								return false;
							}}
						>
							<Button icon={<UploadOutlined />}>Select File</Button>
						</Upload>
					</Form.Item>
					<Form.Item label="Receipts">
						<Upload
							accept="image/png,image/jpeg,image/jpg"
							onRemove={(file) => {
								const index = fileList.indexOf(file);
								const newFileList = fileList.slice();
								newFileList.splice(index, 1);
								setFileList(newFileList);
							}}
							fileList={fileList}
							beforeUpload={(file: any) => {
								setFileList([...fileList, file]);
								return false;
							}}
						>
							<Button icon={<UploadOutlined />}>Select File</Button>
						</Upload>
					</Form.Item>
					<Form.Item>
						<Button
							loading={isLoading}
							disabled={isLoading}
							size="large"
							block
							type="primary"
							htmlType="submit"
						>
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Card>
		</PageWrapper>
	);
};

export default CreatePillarNumberRequest;
