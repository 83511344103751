import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Header from './TopHeader';

const PageWrapper = ({
	children,
	title,
}: {
	children: ReactNode;
	title?: string;
}) => {
	return (
		<>
			<Helmet>
				<title>{title || 'Pillar Number Application'}</title>
			</Helmet>
			<Style>
				<Header />
				<main>{children}</main>
			</Style>
		</>
	);
};

export default PageWrapper;

const Style = styled.div`
	background-color: white;
	width: 100%;
	position: fixed;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 70px auto;
	height: 100vh;

	& main {
		overflow-y: auto;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 960px;
		padding: 20px;
		position: relative;

		& h2 {
			font-weight: bold;
			font-size: 1.5rem;
		}
	}
`;
