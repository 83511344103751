import { Button } from 'antd';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { removeToken } from '../utils/manage-token';

const MobileMenu = () => {
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);

	const logout = () => {
		removeToken();
		navigate('/');
	};

	return (
		<>
			<button onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
				<span className="material-symbols-outlined">menu</span>
			</button>

			<Style open={open}>
				<div className="top">
					<div>
						<p>BCON</p>
					</div>
					<div>
						<button
							onClick={() => setOpen(false)}
							style={{ cursor: 'pointer' }}
						>
							<span className="material-symbols-outlined">close</span>
						</button>
					</div>
				</div>
				<div className="middle">
					<div>
						<Link to="/">Home</Link>
					</div>
					<div>
						<Link to="/users">Users</Link>
					</div>
					<div>
						<Link to="/profile">Profile</Link>
					</div>
					<div>
						<Link to="/government-owned-land">Govt. Land</Link>
					</div>
					<div>
						<Link to="/settings">Settings</Link>
					</div>
				</div>
				<div className="bottom">
					<Button type="primary" danger size="large" block onClick={logout}>
						Logout
					</Button>
				</div>
			</Style>
		</>
	);
};

export default MobileMenu;

const Style = styled.div<{ open: boolean }>`
	position: fixed;
	width: 100%;
	display: grid;
	grid-template-rows: auto 1fr auto;
	background-color: white;
	height: 100vh;
	top: 0;
	left: -100%;
	transition: all 400ms ease;

	${(p) =>
		p.open &&
		css`
			left: 0;
			z-index: 100;
		`}

	& .top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #ccc;
		font-weight: bold;
	}

	& .middle {
		padding: 20px;
		overflow-y: auto;
		& a {
			display: block;
			padding: 20px;
			text-decoration: none;
			font-size: 24px;
			border-bottom: 1px solid #ccc;
		}
	}

	& .bottom {
		padding: 20px;
		padding-bottom: 100px;
	}
`;
