import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const retrieveSingleRequest = createAsyncThunk(
	'pnrequest/retrieveSingleRequest',
	async (id: any) => {
		const res = await baseuri.get('/v1/pillar-number-requests/' + id);
		return res.data;
	}
);

export const createRequest = async ({ id, body }: any) => {
	const res = await baseuri.post(
		`/v1/surveyors/${id}/pillar-number-requests`,
		body
	);
	return res.data;
};

const profileSlice = createSlice({
	name: 'pnrequest',
	initialState: {
		single: {
			id: 0,
			approvedBy: 0,
			admin: { email: '' },
			area: '',
			createdAt: '',
			surveyLocation: '',
			pillarCount: 0,
			planNumber: '',
			status: '',
			surveyorId: 0,
			updatedAt: '',
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder.addCase(retrieveSingleRequest.fulfilled, (store, action) => {
			store.single = action.payload;
			return store;
		}),
});

export default profileSlice.reducer;
