import { combineReducers } from '@reduxjs/toolkit';
import api from '../api';
import auth from './slices/auth';
import profile from './slices/profile';
import pnRequests from './slices/pillar-num-requests';
import settingsSlice from './slices/settings';

const reducers = combineReducers({
	auth,
	profile,
	settings: settingsSlice,
	pillarNumberRequests: pnRequests,
	[api.reducerPath]: api.reducer,
});

export default reducers;
