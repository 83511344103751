import { Divider } from 'antd';
import PageWrapper from '../../components/PageWrapper';

const SettingsPage = () => {
	return (
		<PageWrapper>
			<h1 className="light">Settings</h1>
			<Divider />
		</PageWrapper>
	);
};

export default SettingsPage;
