import { Button, Card, Pagination, Table, Tag } from 'antd';
import { remakeQueryString, statusColor } from '../../utils/general';
import moment from 'moment';
import { useQuery } from 'react-query';
import { adminStatistics } from '../../utils/redux/slices/profile';
import { getBeaconNumberRequests } from '../../utils/endpoints/admin';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NativeNumber from '../../components/NativeNumber';
import { useEffect } from 'react';

const AdminDashboard = () => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				page: '1',
				pageSize: '5',
			});
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};
	const { data: statistics } = useQuery('statistics', adminStatistics, {
		retry: 0,
	});
	const { data } = useQuery('beacon-requests', getBeaconNumberRequests, {
		retry: 0,
	});

	return (
		<>
			<Card>
				<div
					style={{ display: 'inline-block', marginRight: 20, marginTop: 20 }}
				>
					<p style={{ fontSize: 24, fontWeight: 700 }}>
						<NativeNumber value={statistics?.pending} />
					</p>
					<p>Pending</p>
				</div>
				<div
					style={{ display: 'inline-block', marginRight: 20, marginTop: 20 }}
				>
					<p style={{ fontSize: 24, fontWeight: 700 }}>
						<NativeNumber value={statistics?.approved} />
					</p>
					<p>Approved</p>
				</div>
			</Card>
			<br />
			<Card>
				<div style={{ overflowX: 'auto' }}>
					<Table
						columns={[
							{
								title: 'S/No',
								key: 'no',
								render: (x, r, i) => i + 1,
							},
							{
								title: 'Status',
								dataIndex: 'status',
								key: 'status',
								render: (text) => <Tag color={statusColor(text)}>{text}</Tag>,
							},
							{
								title: 'Surveyor',
								dataIndex: 'surveyor',
								key: 'surveyor',
								render: (surveyor) => (
									<p>
										{surveyor.email} ({surveyor.suffix})
									</p>
								),
							},
							{
								title: 'Location',
								dataIndex: 'surveyLocation',
								key: 'surveyLocation',
							},
							{
								title: 'Area (Sq.Meters)',
								dataIndex: 'area',
								key: 'area',
								render: (area) => <NativeNumber value={area} />,
							},
							{
								title: 'Pillar Count',
								dataIndex: 'pillarCount',
								key: 'pillarCount',
								render: (pillarCount) => <NativeNumber value={pillarCount} />,
							},
							{
								title: 'Request Date',
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text) => moment(text).format('lll'),
							},
							{
								title: '',
								dataIndex: 'id',
								key: 'id',
								// fixed: 'right',
								render: (requestId) => (
									<Button
										type="primary"
										onClick={() => navigate(`/requests/${requestId}`)}
									>
										View Request
									</Button>
								),
							},
						]}
						dataSource={data?.item || []}
						pagination={false}
					/>

					<br />

					<Pagination
						showSizeChanger
						onShowSizeChange={(page, pageSize) => {
							updateQueryString({ page, pageSize });
						}}
						current={Number(data?.currentPage)}
						total={Number(data?.totalItems) || 0}
						pageSize={Number(data?.pageSize) || 0}
						onChange={(page, pageSize) => {
							updateQueryString({ page, pageSize });
						}}
					/>
					<br />
					<br />
				</div>
			</Card>
		</>
	);
};

export default AdminDashboard;
