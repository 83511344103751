import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import PageWrapper from '../../components/PageWrapper';
import styled from 'styled-components';
import { Select } from 'antd';

export default function GovernmentOwnedLand() {
	return (
		<PageWrapper title="Government Land">
			<h1 className="light">Government Land</h1>
			<Select
				placeholder="Find restricted areas"
				size="large"
				style={{ width: '100%' }}
			>
				<Select.Option>Emene</Select.Option>
			</Select>
			<Style>
				<MapContainer
					center={[6.42623, 7.50712]}
					zoom={13}
					scrollWheelZoom={false}
				>
					<TileLayer
						attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
				</MapContainer>
			</Style>
		</PageWrapper>
	);
}

const Style = styled.div`
	height: 100vh;
	margin-top: 20px;

	& .leaflet-container {
		height: 100%;
	}
`;
