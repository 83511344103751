import { Divider, Table } from 'antd';
import { useQuery } from 'react-query';
import { getAdministratorsList } from '../../utils/endpoints/admin';
import { Link } from 'react-router-dom';

export default function AdminList() {
	const { data, isLoading } = useQuery('admins', getAdministratorsList);

	return (
		<div>
			<h1 className="light">Administrators</h1>
			<p>
				We have a total of {data?.totalItems || 0} administrators on this
				platform
			</p>
			<Divider />
			<Table
				loading={isLoading}
				columns={[
					{
						title: '',
						render(value, record, index) {
							return index + 1;
						},
					},
					{
						title: 'Name',
						render(value, record) {
							return (
								<span>
									{record?.user?.firstName} {record.user.lastName}
								</span>
							);
						},
					},
					{
						title: 'Email',
						dataIndex: 'email',
					},
					{
						title: '',
						render: () => <Link to="">Details</Link>,
					},
				]}
				dataSource={data?.item || []}
			/>
		</div>
	);
}
