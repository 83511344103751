import api from '../api';
import baseuri from '../baseuri';

const adminService = api.injectEndpoints({
	endpoints: (build) => ({
		getRequests: build.query<any, any>({
			query: () => ({
				url: `/v1/pillar-number-requests`,
			}),
		}),
		approveRequest: build.mutation({
			query: (id) => ({
				url: `/v1/pillar-number-requests/${id}`,
				method: 'POST',
			}),
		}),
	}),
});

export const { useGetRequestsQuery, useApproveRequestMutation } = adminService;

export default adminService;

export const getBeaconNumberRequests = async () => {
	const res = await baseuri.get('/v1/pillar-number-requests');
	return res.data;
};

export const approveRequest = async (requestId: any) => {
	const res = await baseuri.post(`/v1/pillar-number-requests/approve`, {
		requestId,
	});
	return res.data;
};

export const getAdministratorsList = async () => {
	const res = await baseuri.get(`/v1/administrators`);
	return res.data;
};
