import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';

export const retrieveProfileInfo = createAsyncThunk(
	'profile/retrieveProfileInfo',
	async () => {
		const res = await baseuri.get('/v1/profile');
		return res.data;
	}
);

export const adminStatistics = async () => {
	const res = await baseuri.get('/v1/profile/statistics');
	return res.data;
};

const profileSlice = createSlice({
	name: 'profile',
	initialState: {
		adminStats: {
			pending: 0,
			approved: 0,
		},
		profile: {
			id: 0,
			firstName: '',
			lastName: '',
			email: '',
			user: {
				email: '',
				firstName: '',
				id: 0,
				lastName: '',
			},
		},
	},
	reducers: {},
	extraReducers: (builder) =>
		builder.addCase(retrieveProfileInfo.fulfilled, (store, action) => {
			store.profile = action.payload;
			return store;
		}),
});

export default profileSlice.reducer;
