import api from '../api';
import baseuri from '../baseuri';

const surveyorService = api.injectEndpoints({
	endpoints: (build) => ({
		makeRequests: build.mutation<any, any>({
			query: ({ id, body }) => ({
				url: `/v1/surveyors/${id}/pillar-number-requests`,
				method: 'POST',
				body,
			}),
		}),
		getRequestPillarNumbers: build.query({
			query: (id) => ({
				url: `/v1/pillar-number-requests/${id}/pillar-numbers`,
			}),
		}),
		getRequestDocuments: build.query({
			query: (id) => ({
				url: `/v1/pillar-number-requests/${id}/documents`,
			}),
		}),
	}),
});

export const {
	useGetRequestPillarNumbersQuery,
	useMakeRequestsMutation,
	useGetRequestDocumentsQuery,
} = surveyorService;

export default surveyorService;

export const getSurveyorsRequests = async (id: any, params: any) => {
	const res = await baseuri.get(`/v1/surveyors/${id}/pillar-number-requests`, {
		params,
	});
	return res.data;
};

export const getSurveyorsList = async () => {
	const res = await baseuri.get(`/v1/surveyors`);
	return res.data;
};
