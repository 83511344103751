import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../components/PageWrapper';
import styled from 'styled-components';

const LandingPage = () => {
	const navigate = useNavigate();

	return (
		<PageWrapper title="BCON">
			<Style>
				<h1 style={{ fontSize: '3rem', color: '#303030' }}>
					Effortless Beacon Number Requests
				</h1>
				<p style={{ color: '#4d4d4d' }}>
					We understand that time is precious, and complexity is the last thing
					you need. BCON streamlines the entire process, ensuring you can
					request your beacon numbers with ease.
				</p>
				<div style={{ marginTop: 50 }}>
					<Space direction="horizontal" size={10}>
						<Button
							type="primary"
							size="large"
							onClick={() => navigate('/auth')}
						>
							Sign in
						</Button>

						<Button
							size="large"
							onClick={() => navigate('/create-demo-account')}
						>
							Create Demo Account
						</Button>
					</Space>
				</div>
			</Style>
		</PageWrapper>
	);
};

export default LandingPage;

const Style = styled.div`
	text-align: center;
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;

	& p {
		line-height: 1.5;
	}
`;
