import { Button, Card, Pagination, Progress, Table, Tag } from 'antd';
import { useQuery } from 'react-query';
import { getSurveyorsRequests } from '../../utils/endpoints/surveyor';
import { remakeQueryString, statusColor } from '../../utils/general';
import moment from 'moment';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import NativeNumber from '../../components/NativeNumber';
import { PlusOutlined } from '@ant-design/icons';
import { getSurveyorsBeaconCount } from '../../utils/endpoints/profile';
import { useAppSelector } from '../../utils/redux/store';
import { useEffect } from 'react';

const SurveyorsDashboard = ({ id }: { id: any }) => {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		const queryData = Object.fromEntries(searchParams.entries());
		if (Object.keys(queryData).length === 0) {
			setSearchParams({
				page: '1',
				pageSize: '5',
			});
		}
	}, []);

	const updateQueryString = (query: any, action = 'update') => {
		remakeQueryString(query, searchParams, setSearchParams, action);
	};

	const { settings } = useAppSelector((store) => store.settings);
	const {
		refetch: refetchRequest,
		data: beaconNumberRequests,
		isLoading: historyLoading,
	} = useQuery(
		'requests',
		() =>
			getSurveyorsRequests(id, {
				...Object.fromEntries(searchParams.entries()),
			}),
		{ retry: 0 }
	);

	const { data: surveyorStats } = useQuery(
		'beacon-stats',
		getSurveyorsBeaconCount,
		{ retry: 0 }
	);

	const getPercentage = () => {
		if (
			surveyorStats?.totalBeaconNumbers &&
			settings?.totalMonthlyBeaconNumbersForSurveyors
		) {
			return Math.floor(
				(Number(surveyorStats.totalBeaconNumbers) /
					Number(settings.totalMonthlyBeaconNumbersForSurveyors)) *
					100
			);
		}
		return 0;
	};

	useEffect(() => {
		refetchRequest();
	}, [searchParams]);

	return (
		<div>
			<Card style={{ textAlign: 'center' }}>
				<Progress type="circle" size="default" percent={getPercentage()} />
				<p style={{ marginTop: 10 }}>
					You have{' '}
					<span>
						{Number(settings?.totalMonthlyBeaconNumbersForSurveyors) -
							Number(surveyorStats?.totalBeaconNumbers)}
					</span>{' '}
					beacon numbers left out of your{' '}
					{settings?.totalMonthlyBeaconNumbersForSurveyors} for the month
				</p>
				<br />
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						onClick={() => navigate('/request')}
						size="large"
						icon={<PlusOutlined />}
						type="primary"
					>
						Request Beacon Numbers
					</Button>
				</div>
			</Card>
			<br />
			<Card>
				<p>History</p>
				<div style={{ marginTop: 20, overflowX: 'scroll' }}>
					<Table
						loading={historyLoading}
						columns={[
							{
								title: 'S/No',
								key: 'no',
								render: (x, r, i) => i + 1,
							},
							{
								title: 'Status',
								dataIndex: 'status',
								key: 'status',
								render: (value) => (
									<Tag color={statusColor(value)}>{value}</Tag>
								),
							},
							{
								title: 'Area (Sq.Meters)',
								dataIndex: 'area',
								key: 'area',
								render: (area) => <NativeNumber value={area} />,
							},
							{
								title: 'Pillar Count',
								dataIndex: 'pillarCount',
								key: 'pillarCount',
							},
							{
								title: 'Location',
								dataIndex: 'surveyLocation',
								key: 'surveyLocation',
							},
							{
								title: 'Date Created',
								dataIndex: 'createdAt',
								key: 'createdAt',
								render: (text) => moment(text).format('lll'),
							},
							{
								title: '',
								dataIndex: 'id',
								key: 'x',
								render: (id) => <Link to={`/requests/${id}`}>View</Link>,
							},
						]}
						dataSource={beaconNumberRequests?.item || []}
						pagination={false}
					/>

					<br />

					<Pagination
						showSizeChanger
						onShowSizeChange={(page, pageSize) => {
							updateQueryString({ page, pageSize });
						}}
						current={Number(beaconNumberRequests?.currentPage)}
						total={Number(beaconNumberRequests?.totalItems) || 0}
						pageSize={Number(beaconNumberRequests?.pageSize) || 0}
						onChange={(page, pageSize) => {
							updateQueryString({ page, pageSize });
						}}
					/>
					<br />
					<br />
				</div>
			</Card>
		</div>
	);
};

export default SurveyorsDashboard;
