import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import baseuri from '../../baseuri';
import { message } from 'antd';
import { saveToken } from '../../manage-token';
import gotoPage from '../../goto-page';

export const adminLogin = createAsyncThunk(
	'auth/adminLogin',
	async (body: any) => {
		const res = baseuri.post('/v1/administrators/token', body);
		return res;
	}
);

export const login = async (body: any) => {
	const res = baseuri.post('/v1/auth', body);
	return res;
};

export const createSurveyorDemoAccount = async (body: any) => {
	const res = baseuri.post('/v1/surveyors', body);
	return res;
};

export const createAdminDemoAccount = async (body: any) => {
	const res = baseuri.post('/v1/administrators', body);
	return res;
};

const authSlice = createSlice({
	name: 'auth',
	initialState: {},
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(adminLogin.fulfilled, (store, action) => {
				saveToken(action.payload.data.token);
				gotoPage('/admin');
				return store;
			})
			.addCase(adminLogin.rejected, (store, action) => {
				message.error(action.error.message);
			}),
});

export default authSlice.reducer;
