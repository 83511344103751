import { Divider, Table } from 'antd';
import { useQuery } from 'react-query';
import { getSurveyorsList } from '../../utils/endpoints/surveyor';
import { Link } from 'react-router-dom';

export default function SurveyorsList() {
	const { data, isLoading } = useQuery('surveyors', getSurveyorsList);

	return (
		<div>
			<h1 className="light">Surveyors</h1>
			<p>
				We have a total of {data?.totalItems || 0} surveyors on this platform
			</p>
			<Divider />
			<Table
				loading={isLoading}
				columns={[
					{
						title: '',
						render(value, record, index) {
							return index + 1;
						},
					},
					{
						title: 'Name',
						render(value, record) {
							return (
								<span>
									{record.user?.firstName} {record.user?.lastName} (
									{record.suffix})
								</span>
							);
						},
					},
					{
						title: 'Email',
						dataIndex: 'email',
					},
					{
						title: '',
						render: () => <Link to="">Details</Link>,
					},
				]}
				dataSource={data?.item || []}
			/>
		</div>
	);
}
