import Axios from 'axios';
import { chooseBaseUri } from './api';
import { removeToken, retrieveToken } from './manage-token';
import gotoPage from './goto-page';

const baseuri = Axios.create({
	baseURL: chooseBaseUri(),
	headers: {
		Authorization: null,
	},
});

baseuri.interceptors.request.use((request) => {
	const token = retrieveToken();

	if (request.headers) {
		request.headers.Authorization = 'Bearer ' + token;
	}

	return request;
});

baseuri.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response?.status === 401) {
			removeToken();
			gotoPage('/');
		}
		throw error?.response?.data || error?.message;
	}
);

export default baseuri;
