import { useNavigate } from 'react-router-dom';
import { removeToken } from '../utils/manage-token';
import { useAppDispatch, useAppSelector } from '../utils/redux/store';
import { useEffect } from 'react';
import { retrieveProfileInfo } from '../utils/redux/slices/profile';
import { Button, Divider } from 'antd';
import PageWrapper from '../components/PageWrapper';

const Profile = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { profile } = useAppSelector((store) => store.profile);

	const logout = () => {
		removeToken();
		navigate('/');
	};

	useEffect(() => {
		dispatch(retrieveProfileInfo());
	}, []);

	return (
		<PageWrapper>
			<h1>Welcome, {profile.user?.firstName}</h1>
			<p>{profile.email}</p>
			<Divider />
			<Button onClick={() => navigate('/surveyor')} type="primary">
				Go To Dashboard
			</Button>
			<Divider />
			<Button onClick={logout} danger>
				Logout
			</Button>
		</PageWrapper>
	);
};

export default Profile;
