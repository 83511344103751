import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
	name: 'settings',
	initialState: {
		settings: {
			id: 0,
			pillarRequestCurrentPrefix: '',
			pillarRequestStartingNumber: 0,
			totalMonthlyBeaconNumbersForSurveyors: 0,
		},
	},
	reducers: {
		setSettings: (state, action) => {
			state.settings = action.payload;
		},
	},
});

export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
