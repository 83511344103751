import { RouteObject } from 'react-router-dom';
import CreatePillarNumberRequest from '../pages/CreateRequest';
import SinglePillarRequestPage from '../pages/SinglePillarRequest';
import Profile from '../pages/Profile';
import Dashboard from '../pages/dashboard';
import SettingsPage from '../pages/settings';
import ApplicationUsers from '../pages/users';
import GovernmentOwnedLand from '../pages/government-owned';

const authroutes: RouteObject[] = [
	{
		path: 'request',
		element: <CreatePillarNumberRequest />,
	},
	{
		path: 'requests/:id',
		element: <SinglePillarRequestPage />,
	},
	{
		path: 'profile',
		element: <Profile />,
	},
	{
		path: 'dashboard',
		element: <Dashboard />,
	},
	{
		path: 'settings',
		element: <SettingsPage />,
	},
	{
		path: 'users',
		element: <ApplicationUsers />,
	},
	{
		path: 'government-owned-land',
		element: <GovernmentOwnedLand />,
	},
];

export default authroutes;
