import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import GlobalStyles from './GlobalStyles';
import UnAuthenticatedRoutes from './components/UnAuthenticated';
import unauthenticated from './routes/unauthenticated';
import AuthRoutes from './components/AuthRoutes';
import authroutes from './routes/authroutes';

const router = createBrowserRouter([
	{
		path: '/',
		element: <UnAuthenticatedRoutes />,
		children: unauthenticated,
	},
	{
		path: '/',
		element: <AuthRoutes />,
		children: authroutes,
	},
]);

function App() {
	return (
		<>
			<GlobalStyles />
			<RouterProvider router={router} />
		</>
	);
}

export default App;
