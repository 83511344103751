const tokenName = 'auth-token';

export const retrieveToken = () => {
	const data = localStorage.getItem(tokenName);
	if (data) {
		return data;
	}
	return null;
};

export const saveToken = (token: any) => {
	localStorage.setItem(tokenName, token);
};

export const removeToken = () => {
	localStorage.removeItem(tokenName);
};

export const isAuthenticated = (): boolean => {
	const token = retrieveToken();
	return !!token;
};
