import baseuri from '../baseuri';

export const getProfile = async () => {
	const res = await baseuri.get('/v1/profile');
	return res.data;
};

export const getSurveyorsBeaconCount = async () => {
	const res = await baseuri.get('/v1/profile/surveyor-stats');
	return res.data;
};

export const getSettings = async () => {
	const res = await baseuri.get('/v1/internal-settings');
	return res.data;
};
