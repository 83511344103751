import {
	Image,
	Button,
	Card,
	Modal,
	Table,
	Tag,
	message,
	notification,
	Space,
} from 'antd';
import {
	useGetRequestDocumentsQuery,
	useGetRequestPillarNumbersQuery,
} from '../utils/endpoints/surveyor';
import { useParams } from 'react-router-dom';
import PageWrapper from '../components/PageWrapper';
import { useEffect } from 'react';
import { retrieveSingleRequest } from '../utils/redux/slices/pillar-num-requests';
import { useAppDispatch, useAppSelector } from '../utils/redux/store';
import styled from 'styled-components';
import { statusColor } from '../utils/general';
import NativeNumber from '../components/NativeNumber';
import { useMutation, useQuery } from 'react-query';
import { approveRequest } from '../utils/endpoints/admin';
import { getProfile } from '../utils/endpoints/profile';

const SinglePillarRequestPage = () => {
	const dispatch = useAppDispatch();
	const { data: profile } = useQuery('profile', getProfile, { retry: 0 });

	const params = useParams();
	const { data } = useGetRequestPillarNumbersQuery(params.id);
	const { data: documents } = useGetRequestDocumentsQuery(params.id);

	const { single } = useAppSelector((store) => store.pillarNumberRequests);

	useEffect(() => {
		dispatch(retrieveSingleRequest(params.id));
	}, []);

	return (
		<PageWrapper>
			<h1 className="light">Beacon Number Request</h1>
			<Style>
				<Card>
					<p>Request Information</p>
					<br />
					<div>
						<Tag color={statusColor(single.status)}>{single.status}</Tag>
					</div>
					<div>
						<div className="info">
							<p>{single.surveyLocation}</p>
							<p className="label">Location</p>
						</div>
						<div className="info">
							<p>{single.planNumber}</p>
							<p className="label">Plan Number</p>
						</div>
						<div className="info">
							<p>
								<NativeNumber value={single.area} /> m<sup>2</sup>
							</p>
							<p className="label">Area</p>
						</div>
						<div className="info">
							<p>{single.pillarCount}</p>
							<p className="label">No. of pillars</p>
						</div>
						<div className="info">
							<p>{single.admin?.email || '--'}</p>
							<p className="label">Approve By</p>
						</div>
					</div>
					{profile?.userType === 'ADMIN' && single?.status === 'PENDING' && (
						<div>
							<br />
							<Space size={15}>
								<ApproveRequest requestId={params.id} />
								<Button disabled>Request Additional Details</Button>
								<Button disabled danger>
									Cancel Request
								</Button>
							</Space>
						</div>
					)}
				</Card>
				<br />
				<Card>
					<p>Beacon Numbers</p>
					<br />
					<Table
						dataSource={data?.item || []}
						columns={[
							{
								title: 'S/No',
								key: 'no',
								render: (x, r, i) => i + 1,
							},
							{
								title: 'Number',
								key: 'q',
								render: (record) => {
									return (
										<p>
											{record.prefix}
											{record.code}
											{record.suffix}
										</p>
									);
								},
							},
						]}
						pagination={false}
					/>
					<br />

					{/* <Pagination
						showSizeChanger
						onShowSizeChange={(page, pageSize) => {
							updateQueryString({ page, pageSize });
						}}
						current={Number(data?.currentPage)}
						total={Number(data?.totalItems) || 0}
						pageSize={Number(data?.pageSize) || 0}
						onChange={(page, pageSize) => {
							updateQueryString({ page, pageSize });
						}}
					/>
					<br />
					<br /> */}
				</Card>
				<br />
				<Card>
					<p>Documents</p>
					<br />
					<Space size={12}>
						{documents?.map((docs: any) => (
							<Image
								style={{ marginBottom: 10 }}
								key={docs.id}
								width={200}
								src={docs.documentUrl}
							/>
						))}
					</Space>
				</Card>
			</Style>
		</PageWrapper>
	);
};

export default SinglePillarRequestPage;

const Style = styled.div`
	& .info {
		display: inline-block;
		margin-top: 20px;
		margin-right: 20px;

		& .label {
			color: #acacac;
		}
	}
`;

const ApproveRequest = ({ requestId }: { requestId: any }) => {
	const { mutate: approve, isLoading } = useMutation(approveRequest, {
		onSuccess: () => {
			message.success('Request Approved!');
			location.reload();
		},
		onError: (error: any) => {
			notification['error']({
				message: 'Action Failed',
				description: error.message,
			});
		},
		retry: 0,
	});

	const handleApproval = () => {
		approve(requestId);
	};

	return (
		<Button
			loading={isLoading}
			onClick={() =>
				Modal.confirm({
					onOk: handleApproval,
					okText: 'Approve',
					title: 'Approve Request',
					content:
						'Are you sure you want to approve this pillar number request',
				})
			}
			type="primary"
		>
			Approve
		</Button>
	);
};
