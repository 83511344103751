import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MobileMenu from './MobileMenu';

const TopHeader = () => {
	const navigate = useNavigate();

	const location = useLocation();

	const excluded = ['/dashboard', '/'];

	return (
		<Style>
			<div>
				<Link to="/">BCON</Link>
			</div>
			<div>
				<MobileMenu />
			</div>

			{!excluded.includes(location.pathname) && (
				<button className="back-button" onClick={() => navigate(-1)}>
					<span className="material-symbols-outlined">arrow_back</span>
				</button>
			)}
		</Style>
	);
};

export default TopHeader;

const Style = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px;
	z-index: 1;
	background-color: white;
	width: 100%;
	border-bottom: 1px solid #ccc;
	position: relative;

	& button {
		border: none;
		background-color: transparent;
	}

	& .back-button {
		position: absolute;
		border: none;
		top: 50px;
		background-color: #d7d7d7;
		color: #8d8d8d;
		display: inline-flex;
		width: 42px;
		height: 42px;
		cursor: pointer;
		border-radius: 50%;
		justify-content: center;
		align-items: center;
	}
`;
