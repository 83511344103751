import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { retrieveToken } from './manage-token';

export const chooseBaseUri = () => {
	const location = window.location;
	if (location.hostname !== 'localhost') {
		return 'https://pillar-api.mrcally.com';
	}
	return 'http://localhost:4000';
};

const base = fetchBaseQuery({
	baseUrl: chooseBaseUri(),
});

const customBaseQuery = async (args: any, api: any, extraOptions: any) => {
	const token = retrieveToken();
	const result = await base(
		{
			...args,
			headers: { ...extraOptions?.headers, authorization: `Bearer ${token}` },
		},
		api,
		extraOptions
	);

	return result;
};

const api = createApi({
	reducerPath: 'epnr-api',
	baseQuery: customBaseQuery,
	endpoints: () => ({}),
});

export default api;
