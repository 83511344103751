import { useQuery } from 'react-query';
import { getProfile, getSettings } from '../../utils/endpoints/profile';
import PageWrapper from '../../components/PageWrapper';
import Surveyor from './Surveyors';
import { Divider } from 'antd';
import AdminDashboard from './Admin';
import { useEffect } from 'react';
import { useAppDispatch } from '../../utils/redux/store';
import { setSettings } from '../../utils/redux/slices/settings';

function Dashboard() {
	const dispatch = useAppDispatch();
	const { data: settings } = useQuery('internal-settings', getSettings, {
		retry: 0,
	});

	useEffect(() => {
		dispatch(setSettings(settings));
	}, [settings]);

	const { data: profile } = useQuery('profile', getProfile, { retry: 0 });

	return (
		<PageWrapper title="Dashboard">
			<div style={{ textAlign: 'center' }}>
				<p>
					{profile?.profile?.user?.firstName
						? `${profile?.profile?.user?.firstName}'s `
						: ''}
					Dashboard
				</p>
				<p>{profile?.profile?.email}</p>
			</div>
			<Divider />
			{profile && profile.userType === 'SURVEYOR' && (
				<Surveyor id={profile?.profile?.id} />
			)}
			{profile && profile.userType === 'ADMIN' && <AdminDashboard />}
		</PageWrapper>
	);
}

export default Dashboard;
