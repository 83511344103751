import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../utils/manage-token';

const AuthRoutes = () => {
	const navigate = useNavigate();

	useEffect(() => {
		if (!isAuthenticated()) {
			navigate('/');
		}
	}, [navigate]);

	if (isAuthenticated()) {
		return <Outlet />;
	}

	return null;
};

export default AuthRoutes;
